<template>
  <div>
    <b-modal title="쿠폰 발행" ref="modal_view" :hide-footer="true" @shown="init">
      <b-form-group id="fieldset-1">
        <b-input-group prepend="상태">
          <b-form-radio-group v-model="pub_stat_cd" buttons button-variant="outline-primary">
            <b-form-radio value="OK">승인</b-form-radio>
            <b-form-radio value="CANCEL">거절</b-form-radio>
          </b-form-radio-group>
        </b-input-group>

        <!-- <b-input-group prepend="쿠폰명" class="mt-2" v-if="pub_stat_cd === 'OK'">
          <b-form-input v-model="cp_name" placeholder="PLUS-UP05 구독권30일"></b-form-input>
        </b-input-group> -->
        <b-input-group prepend="쿠폰유효시작일" class="mt-2" v-if="pub_stat_cd === 'OK'">
          <b-form-datepicker v-model="cp_start_dt" :hide-header="true"></b-form-datepicker>
        </b-input-group>
        <b-input-group prepend="쿠폰유효종료일" class="mt-2" v-if="pub_stat_cd === 'OK'">
          <b-form-datepicker v-model="cp_end_dt" :hide-header="true"></b-form-datepicker>
        </b-input-group>

        <br /><br />
        <b-button @click="close">취소</b-button>
        <b-button variant="danger" @click="submit" class="ml-2">확인</b-button>
      </b-form-group>
    </b-modal>
  </div>
</template>

<script>
import { plusup_update } from '../../api/site'

export default {
  data() {
    return {
      pub_stat_cd: 'OK',
      cp_name: '',
      cp_start_dt: '',
      cp_end_dt: ''
    }
  },
  props: {
    pub_sn: null
  },
  methods: {
    close() {
      console.log(this.cp_end_dt)
      this.$refs['modal_view'].hide()
    },
    async submit() {
      let start_dt = '',
        end_dt = ''
      if (this.pub_stat_cd === 'OK') {
        if (this.cp_start_dt == '' || this.cp_end_dt == '') {
          alert('쿠폰정보를 입력해주세요')
          return
        }
        start_dt = this.cp_start_dt.replace(/-/g, '')
        end_dt = this.cp_end_dt.replace(/-/g, '')
      }
      const response = await plusup_update(
        this.pub_stat_cd,
        this.pub_sn,
        this.cp_name,
        start_dt,
        end_dt
      )
      if (response.code === '0000') {
        alert('처리완료되었습니다.')
        this.$emit('refresh')
        this.close()
      } else {
        alert(response.message)
      }
    },
    init() {
      this.pub_stat_cd = 'OK'
      this.cp_name = ''
      this.cp_start_dt = ''
      this.cp_end_dt = ''
    }
  }
}
</script>
